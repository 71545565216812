// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue.js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-objets-js": () => import("./../../../src/pages/objets.js" /* webpackChunkName: "component---src-pages-objets-js" */),
  "component---src-pages-ounoustrouver-js": () => import("./../../../src/pages/ounoustrouver.js" /* webpackChunkName: "component---src-pages-ounoustrouver-js" */),
  "component---src-pages-plus-dinfos-js": () => import("./../../../src/pages/plusDinfos.js" /* webpackChunkName: "component---src-pages-plus-dinfos-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-evenement-js": () => import("./../../../src/templates/evenement.js" /* webpackChunkName: "component---src-templates-evenement-js" */)
}

